<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="As referências simbólicas"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Para além das capelas que se constituem (compõem) como
                referências identitárias para as pessoas da comunidade, outros
                elementos físicos eram de grande importância e fazem parte da
                memória de uma vivência e de um território que foi destruído.
                Entre esses bens materiais (que se pode tocar) que fazem parte
                do patrimônio, da história dos atingidos de Bento Rodrigues
                destaca-se os bancos compostos por placas de pedra, que se
                situavam na praça próximo ao Bar da Sandra, ao lado da Capela de
                São Bento.
              </p>
              <p>
                Durante os trabalhos de escavação arqueológica realizados pela
                Fundação Renova, os atingidos exigiram o direito de
                participação, e em meio à lama que soterrou a comunidade,
                encontraram as lajes de pedra a alguns metros de onde os bancos
                se situavam. Esse processo de evidenciação (trazer à vista) faz
                com que as emoções voltem imediatamente, junto com o sentimento
                de pertencimento e valorização do patrimônio, do que faz parte
                da identidade e das tradições desse grupo de pessoas. Foi
                justamente o que aconteceu com a atingida Maria ao encontrar o
                banco onde costumava se sentar desde criança.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-end align-md-end"
        >
          <v-img
            max-width="80%"
            src="../../../assets/sobre_bento_tradicao_referencias_01.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:80%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Atingidos Maria e Cristiano ao encontrarem as lajes do banco de
            pedra onde ela gostava de ficar. Fonte: Jornal A Sirene, Ed. 10,
            janeiro de 2017.
          </p>
          <v-img
            max-width="80%"
            src="../../../assets/sobre_bento_tradicao_referencias_02.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:80%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Maria deitada sobre uma das lajes do banco de pedra após
            reencontrá-las. Fonte: Jornal A Sirene, Ed. 10, janeiro de 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>

            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
